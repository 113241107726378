$primary: #445E54;
$primary-medium: #B9C9BD;
$secondary: #BFD0C3;
$primary-light: #F7F9F8;
$tertiary: #DCC7B6;
$grey: #7F7F7F;
$modal-background-background-color: $primary;
$modal-card-body-background-color: #fff;
$modal-close-right: 40px;
$modal-close-top: 35px;
@import 'node_modules/bulma/bulma.sass';
@import 'spacing.scss';
@import "node_modules/bulma-calendar/src/sass/index.sass";
@import "node_modules/bulma-carousel/src/sass/index.sass";

@mixin josefin {
    font-family: 'Josefin Sans', sans-serif;
}

@mixin muli {
    font-family: 'Muli', sans-serif;
}
body {
    @include mobile {
        overflow-x: hidden;
    }
}
.is-josefin {
    @include josefin;
}
.is-muli {
    @include muli;
}

.line-height-2 {
    line-height: 2em;
}
.is-flex {
    display: flex;
}
.green-bar {
    border-top: 5px solid $primary;
    margin-bottom: 10px;
}
.green-bg {
    background-color: $primary;
}
.is-relative {
    position: relative;
}
.navbar-link:hover {
    color: unset;
}
.navbar {
    margin-top: 10px;
}
.navbar-link {
    width: 43px;
    height: 43px;
    margin-left: 1em;
}
.register-modal {
    @include josefin;
    &.navbar-item:hover {
        background-color: $tertiary;
        color: $primary;
        text-decoration: underline;
    }
}
#menuModal {
    width: 50%;
    left: unset;
    right: 0;
    @include mobile {
        width: 100%;
    }
    .navbar-item {
        color: $tertiary;
        font-weight: bold;
        width: 100%;
        padding-left: 10%;
        height: 75px;
        font-size: 1.5em;
        text-transform: uppercase;
        @include josefin;
    }
    a.navbar-item:hover {
        background-color: unset;
        text-decoration: underline;
    }
}

.menu-dropdown {
    background-color: $primary;
    width: 100%;
    margin-top: 20%;
    .navbar-item {
        color: $tertiary;
        height: 75px;
        padding-left: 10%;
        font-weight: bold;
    }
}
.m-t-6 {
    margin-top: 6rem;
    @include mobile {
        margin-top: 0;
    }
}
.rslides {
    list-style: none;
    overflow: hidden;
    height: 100vh;
    padding: 0;
    margin: 0;

    li {
        -webkit-backface-visibility: hidden;
        position: absolute;
        display: none;
        left: 0;
        top: 0;
    }

    li:first-child {
        position: relative;
        display: block;
        float: left;
    }

    img {
        display: block;
        float: left;
        border: 0;
        min-width: 100vw;
        min-height: 100vh;
        @include mobile {
            min-width: unset;
            max-width: unset;
            height: 100vh;
            margin-left: -30%;
        }
    }
}

.transparent-overlay {
    background-color: $black;
    opacity: .5;
    height: 50vh;
    top: 50vh;
    width: 100%;
    z-index: 5;
    position: absolute;
}

.icon-overlay {
    z-index: 6;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    top: 37%;
}

.nature {
    @extend .m-b-xxl, .m-t-xl;
    @include mobile {
        margin-bottom: 0;
    }
}

@mixin watermark {
    color: $primary;
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $primary;
    font-size: 14em;
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
}

.watermark-left {
    @include watermark;
    z-index: 10;
    position: absolute;
    line-height: 1em;
    left: -20rem;
    @include mobile {
        visibility: hidden;
    }
}

.watermark-right {
    @include watermark;
    z-index: 10;
    position: absolute;
    line-height: 17em;
    right: -21rem;
    @include mobile {
        visibility: hidden;
    }
}

.amenities-watermark {
    @include watermark;
    z-index: 10;
    position: absolute;
    line-height: 1em;
    right: -2em;
    top: 0;
    @include mobile {
        visibility: hidden;
    }
}

.vl {
    background-color: $secondary;
    width: 4px;
    height: 86%;
    @include mobile {
        position: absolute;
        top: 0;
        height: 100%;
    }
}

.arrow-down {
    width: 0; 
    height: 0; 
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid $secondary;
    margin-left: -18px;
}

.feature-text {
    padding: 3em;
    width: 45%;
    img {
        width: 75px;
        height: 75px;
    }
    h1 {
        color: $tertiary;
        text-transform: uppercase;
        font-weight: bold;
    }
    h1:after {
        content: ""; /* This is necessary for the pseudo element to work. */ 
        display: block; /* This will put the pseudo element on its own line. */
        width: 30px; /* Change this to whatever width you want. */
        padding-top: 20px; /* This creates some space between the element and the border. */
        border-bottom: 3px solid $tertiary; /* This creates the border. Replace black with whatever color you want. */
    }
    p {
        margin-top: 20px;
        font-size: 1em;
        line-height: 2em;
    }
    @include mobile {
        padding: unset;
        padding-left: 2em;
        width: 100%;
    }
}

.top-left {
    position: absolute;
    top: 4em;
    left: 5em;
    @include mobile {
        position: relative;
        top: unset;
        left: unset;
    }
}

.bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    @include mobile {
        position: relative;
        bottom: unset;
        left: -5%;
    }
}

.bottom-right {
    position: absolute;
    bottom: 0;
    right: 3em;
    @include mobile {
        position: relative;
        bottom: unset;
        right: 5%;
    }
}

.overlap-description-left {
    top: unset;
    padding: 3em 4em 3em 4em;
    @include mobile {
        padding-right: 1em;
        padding-left: 1em;
        position: relative;
    }
    @include desktop {
        @extend .is-overlay;
    }
}

.description {
    h1 {
        @include josefin;
        font-weight: bold;
        font-size: 2em;
        margin-bottom: 1em;
    }
    p {
        @include muli;
        font-size: 1.2rem;
        line-height: 2em;
    }
    @include mobile {
        padding-left: 2em;
        padding-right: 2em;
    }
}

.card-stack {
    height: 550px;
}

.image-card-1, .image-card-2, .image-hover {
    object-fit: cover;
    width: 450px;
    height: 550px;
    object-position: 55% 0;
    position: absolute;
}

.image-card-2 {
    margin-top: 3em;
    margin-left: 3em;
}

.image-hover {
    opacity: 0;
}

.gallery-link {
    color: $black;
    text-decoration: none;
}

.amenities {
    background-color: $primary-light;
    .amenities-icons {
        @include mobile {
            margin: unset;
            margin-left: 1em;
            margin-right: 1em;
        }
    }
    .left-amenities, .right-amenities {
        @include mobile {
            margin: unset;
        }
        > .m-l-xl, .m-l-lg {
            @include mobile {
                margin: unset;
            }
            > .subcategory-title {
                @include mobile {
                    text-align: unset;
                    text-align: left !important;
                    width: 100%;
                }
            }
            > .amenity {
                @include mobile {
                    width: 49%;
                    display: inline-block;
                }
                > * {
                    @include mobile {
                        display: flex;
                        flex-direction: column;
                        align-content: center;
                    }
                }
            }
        }
    }
    .a-icon {
        max-width: 100px;
        @include mobile {
            max-width: unset;
            max-width: 100%;
        }
        @media only screen and (orientation: landscape) {
            max-width: 40%;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .a-title {
        margin-bottom: 1em;
        color: $tertiary;
        font-size: 1em;
        font-weight: bold;
        @include muli;
    }
    .subcategory-title {
        color: $tertiary;
        font-size: 1.5em;
        @include josefin;
    }
    #learn-more {
        @include mobile {
            margin-left: 2.5em;
        }
    }
}

.feature-image {
    max-height: 65vh;
}

.feature-title {
    background-color: $primary;
    color: white;
    text-transform: uppercase;
    font-size: .8rem;
    text-align: center;
    padding: .5em 2em .5em 2em;
    font-weight: bold;
    position: absolute;
    display: block;
    bottom: -4%;
    transform: translate(-50%, -50%);
    left: 50%;
}

// Floor Plans
.floorplans {
    background-color: $primary-light;
    .button-plans {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        .button-plan {
            font-size: 1.3em;
            padding: 1.5em;
            text-transform: uppercase;
            margin-bottom: 2em;
            width: 225px;
            background-color: $primary-medium;
            color: $white;
            border-radius: 0;

            @include josefin;
            @include mobile {
                width: 150px;
                font-size: 1em;
                padding: 1.5em;
            }
        }
        @include mobile {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;
        }
    }
    .floorplan-preview {
        .floor-plan-title {
            font-size: 1.5em;
            font-weight: bold;
            margin-top: 2em;
        }
        .floor-plan {
            height: 100%;
            .floorplan-image {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 0;
                @include mobile {
                    padding-left: 1em;
                }
            }
            > div {
                padding-left: 4em;
                height: 600px;
                @include mobile {
                    padding-left: 1em;
                    height: unset;
                }
                @include widescreen {
                    height: 800px;
                }
                .details {
                    margin-top: 3em;
                    div {
                        border-top: 1px solid #ccc;
                    }
                }
                img {
                    max-height: 510px;
                    @include mobile {
                        top: unset;
                        transform: unset;
                    }
                    @include widescreen {
                        max-height: 680px;
                    }
                }
            }
        }
        .slick-initialized .slick-slide {
            display: flex;
            @include mobile {
                display: unset;
            }
        }
        .slick-slider .slick-track, .slick-slider .slick-list {
            max-height: 100%;
            @include mobile {
                max-height: unset;
            }
        }
        .slick-nav {
            line-height: 0;
            position: absolute;
            top: 50%;
            width: 30px;
            height: 30px;
            padding: 0;
            transform: translate(0, -50%);
            cursor: pointer;
            border: none;
            color: $primary;
            font-size: 2em;
            z-index: 10;
        }
        .slick-nav-prev {
            left: -35px;
            @include mobile {
                left: -10px;
            }
        }
        .slick-nav-next {
            right: -35px;
            @include mobile {
                right: -10px;
            }
        }
        .slick-dots {
            bottom: 20px;
            @include mobile {
                bottom: -90px;
            }
            > li {
                background-color: $primary-medium;
                width: 50px;
                height: 40px;
                color: $white;
                font-size: 1em;
                line-height: 40px;
            }
            > li.slick-active {
                background-color: $primary;
            }
        }
        @include mobile {
            /* hiding all bullets by default */
            .slick-dots li {
                display: none
            }
            /* only displaying the active bullets and the 2 bullets next to it */
            .slick-dots li.slick-active,
            .slick-dots li.slick-active + li,
            .slick-dots li.slick-active + li + li {
                display: inline-block;
            }
            /* displaying the last three bullets when slick-active class isn't applied to any li before them  */
            .slick-dots li:nth-last-child(1),
            .slick-dots li:nth-last-child(2),
            .slick-dots li:nth-last-child(3){
                display: inline-block;
            }
            /* hiding the last three bullets if slick-active exist before them */
            .slick-dots li.slick-active ~ li:nth-last-child(1),
            .slick-dots li.slick-active ~ li:nth-last-child(2),
            .slick-dots li.slick-active ~ li:nth-last-child(3) {
                display: none;
            }
            /* specific conditions to always display the last three bullets */
            .slick-dots li.slick-active + li + li:nth-last-child(3),
            .slick-dots li.slick-active + li + li:nth-last-child(2),
            .slick-dots li.slick-active + li + li:nth-last-child(1),
            .slick-dots li.slick-active + li:nth-last-child(3),
            .slick-dots li.slick-active + li:nth-last-child(2),
            .slick-dots li.slick-active + li:nth-last-child(1){
                display: inline-block;
            }
        }
        .image-plan {
            position: absolute;
            max-width: 90%;
            max-height: 100%;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            visibility: hidden;
            background-color: $white;
            @include mobile {
                max-height: unset;
                width: 100%;
                padding-left: 5%;
                padding-right: 5%;
            }
            @include widescreen {
                max-height: 110%;
            }
        }
    }
}

.plans {
    border: 2px solid $primary;
    padding: 5px;
    width: 100%;
    min-height: 200px;
    .p-title {
        font-size: 5em;
        color: $primary;
    }
    .description {
        font-weight: bold;
    }
    a {
        font-style: italic;
    }
}

.coming-soon {
    text-transform: uppercase;
    color: $primary;
    height: 250px;
    border: 1px solid $primary;
}

// Form Styles
input, select, option {
    @include muli;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid $primary;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: $primary;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
#mc_embed_signup_scroll {
    @include mobile {
        margin-left: 1em;
        margin-right: 1em;
    }
    .field.is-grouped {
        @include mobile {
            flex-direction: column;
        }
    }
}
#mce-success-response, #mce-error-response{
    font-size: 2em;
}
#mce-error-response {
    color: red;
}
.input, .input:focus {
    border: none;
    border-bottom: 1px solid #97A6A0;
    box-shadow: none;
    border-radius: 0px;
}

.input::placeholder {
    color: $grey;
    text-transform: uppercase;
}

.input:checked {
    color: $primary;
}

.select {
    width: 100%;
    select {
        border: none;
        width: 100%;
        border-bottom: 1px solid #97A6A0;
        border-radius: 0px;
    }
}
.select:not(.is-multiple):not(.is-loading)::after {
    border-color: $primary;
}

.is-w2-green {
    background-color: $primary;
    color: $white;
}

.button {
    border: none;
    border-radius: 4px;
}

.button:hover {
    color: $white;
    text-decoration: underline;
}

.section-title {
    font-size: 2em;
    @include muli;
}

.section-subtitle {
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: bold;
    color: $tertiary;
}

.section-description {
    line-height: 2em;
    font-size: 1.2em;
    font-weight: lighter;
    @include muli;
}

.label {
    color: $primary;
    text-transform: uppercase;
    font-weight: bold;
}

// Pagination Styles
.centered-btns_nav {
    z-index: 3;
    position: absolute;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    top: 50%;
    left: 0;
    opacity: 0.7;
    text-indent: -9999px;
    overflow: hidden;
    text-decoration: none;
    height: 61px;
    width: 38px;
    background: transparent url("themes.gif") no-repeat left top;
    margin-top: -45px;
}
  
.centered-btns_nav:active {
    opacity: 1.0;
}
  
.centered-btns_nav.next {
    left: auto;
    background-position: right top;
    right: 0;
}

.centered-btns_nav:focus,
.transparent-btns_nav:focus,
.large-btns_nav:focus {
    outline: none;
}

.centered-btns_tabs,
.transparent-btns_tabs,
.large-btns_tabs {
    position: absolute;
    left: 0;
    right: 0;
    top: 95vh;
    text-align: center;
    z-index: 10;
}

.centered-btns_tabs li,
.transparent-btns_tabs li,
.large-btns_tabs li {
    display: inline;
    float: none;
    _float: left;
    *float: left;
    margin-right: 5px;
}

.centered-btns_tabs a,
.transparent-btns_tabs a,
.large-btns_tabs a {
    text-indent: -9999px;
    overflow: hidden;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    border: 1px solid $white;
    background: rgba(250, 250, 250, 0);
    display: inline-block;
    _display: block;
    *display: block;
    -webkit-box-shadow: inset 0 0 2px 0 rgba(250, 250, 250,.0);
    -moz-box-shadow: inset 0 0 2px 0 rgba(250, 250, 250,0);
    box-shadow: inset 0 0 2px 0 rgba(250, 250, 250,0);
    width: 9px;
    height: 9px;
}

.centered-btns_here a,
.transparent-btns_here a,
.large-btns_here a {
    background: rgba(250, 250, 250, 1);
}

.collage {
    .column {
        overflow: hidden;
    }
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

// Map
.menu-ui {
    background: $white;
    position:absolute;
    top:10px;right:10px;
    z-index:1;
    border-radius:3px;
    width:120px;
    border:1px solid rgba(0,0,0,0.4);
    a {
        font-size:13px;
        color:#404040;
        display:block;
        margin:0;padding:0;
        padding:10px;
        text-decoration:none;
        border-bottom:1px solid rgba(0,0,0,0.25);
        text-align:center;
    }
    a:first-child {
        border-radius:3px 3px 0 0;
    }
    a:last-child {
        border:none;
        border-radius:0 0 3px 3px;
    }
    a:hover {
        background:#f8f8f8;
        color:#404040;
    }
    a.active {
        background:#3887BE;
        color:$white;
    }
    a.active:hover {
        background:#3074a4;
    }
}

.filter-group {
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 600;
    position: absolute;
    bottom: 0;
    z-index: 1;
    border-radius: 3px;
    width: 100%;
    color: $white;
    text-align: center;
}
    
.filter-group label {
    display: inline-block;
    border-bottom: 2px solid $primary;
    width: 200px;
    color: $primary;
    text-align: center;
    font-size: 1.5em;
}
    
.filter-group input[type=checkbox] {
    display: none;
}
    
.filter-group input[type=checkbox] + label {
    background-color: transparent;
    display: inline-block;
    cursor: pointer;
    padding: 10px;
    text-transform: uppercase;
    margin-right: 2em;
}
    
.filter-group input[type=checkbox]:checked + label {
    background-color: $secondary;
}
.marker {
    background-image: url('assets/w2pin1.svg');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}
.mapboxgl-popup {
    max-width: 200px;
}

.mapboxgl-popup-content {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}
  
// End Map

.has-background-none {
    background-color: transparent;
}

.button {
    @include josefin;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 0;
    padding: .5em 2em;
}

.is-secondary {
    background-color: $tertiary;
    color: $primary;
    padding: 1.5em 2em 1.2em 2em;
}

.icon {
    border: 1px solid $white;
    border-radius: 50%;
    font-size: .7em;
}

.hero-head {
    margin-top: 2em;
}

// W2 Watermark
.collage-watermark {
    position: absolute;
    bottom: 1em;
    left: 2em;
    height: unset !important;
    max-width: 150%;
    width: unset !important;
    @include mobile {
        bottom: -6em;
        max-width: 80%;
    }
}

.offset-right {
    position: absolute;
    bottom: -5em;
    right: -15em;
    width: 300px;
    @include mobile {
        top: -5em;
        right: -1em;
        bottom: unset;
    }
}

.offset-middle-right {
    position: absolute;
    right: 0;
    top: 63%;
    width: 350px;
    @include mobile {
        width: 300px;
        top: 48%;
    }
}

// Modal Styles
.modal-content {
    width: 95%;
    overflow: hidden;
    @include mobile {
        overflow: visible;
    }
}

.modal-close::before, .modal-close::after {
    background-color: $tertiary;
}

.modal-close::before {
    width: 70%;
}

.modal-close::after {
    height: 70%;
}

// Form Styles
form {
    color: $grey;
    font-weight: bold;
    @include muli;
}
.control {
    text-transform: uppercase;
}

.small-logo {
    width: 125px;
}

.footer {
    margin-top: -1.9rem;
    .icon {
        margin-left: .5em;
        margin-right: .5em;
    }
}

.portal-login {
    font-size: .9em;
}

.walk-score {
    img {
        max-width: 125px;
    }
    p {
        margin-top: .5em;
        font-size: 1.1em;
    }
}

// Calendar
.datetimepicker-dummy::before, .datetimepicker-dummy::before {
    content: unset;
}
.datetimepicker-dummy {
    height: 2.2rem;
    min-width: 13rem;
}
.datetimepicker-dummy .datetimepicker-dummy-wrapper {
    border: unset;
    border-bottom: 1px solid #97A6A0;
}
.datetimepicker-dummy .datetimepicker-dummy-wrapper .datetimepicker-dummy-input {
    max-width: unset;
    max-width: 100%;
}
.datetimepicker-dummy .datetimepicker-dummy-wrapper .default-move-in {
    height: 2.2rem;
    font-weight: normal;
    color: #bbb;
    padding-top: .3rem;
    width: 100%;
    padding-left: .5rem;
}